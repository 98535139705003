import { getOptions } from "../hooks/getOptions";
import { getCategories } from "../hooks/getCategory";
import { getMenu } from "../hooks/getMenu";
import { GetStaticPropsContext } from "next";
import { v4 as uuidv4 } from "uuid";
import {
  AzFilterPostType,
  CategoryType,
  EncyclopediaCategoryType,
  CategoryDataType,
  PagenationDataType,
  MenuType,
  OptionsType,
  PageType,
  WikiType,
  SEOListType,
  AuthorInsight,
} from "../utils/baseTypes";
import { globalGetStaticPaths } from "../hooks/globalGetStaticPaths";
import { globalGetPageData } from "../hooks/globalGetPageData";
import { Navbar } from "../components/Navbar/Navbar";
import { Footer } from "../components/Footer/Footer";
import { Header } from "../components/Header/Header";
import { WikiRelatedPosts } from "../components/Wiki/WikiRelatedPosts";
import { InlineNav } from "../components/Header/InlineNav/InlineNav";
import { HeadMeta } from "../components/Header/Head";
import { Search } from "../components/Wiki/Search";
import { motion } from "framer-motion";
import { DividerBg } from "../components/Divider/DividerBg";
import { BNavbar } from "../components/Berlinfahrten/BNavbar";
import { BFooter } from "../components/Berlinfahrten/BFooter";
import { FullScreenLoader } from "../Loader/Loader";
import { useRouter } from "next/router";
import { checkSinglePage } from "../utils/checkSinglePage";
const MouseFollow = dynamic(() =>
  import("../components/MouseFollow/MouseFollow").then((l) => l.MouseFollow)
);
import { getPortraitSliderData } from "../getData/getPortraitSliderData";

import dynamic from "next/dynamic";
import { getWikiCommonData } from "../getData/getWikiCommonData";
import { getAuthorHeaderPageData } from "../getData/getAuthorHeaderPageData";
import { getProjectHeaderData } from "../getData/getProjectHeaderData";
import { getAuthorInsightsData } from "../getData/getAuthorInsightsData";
import { getWikiPostData } from "../getData/getWikiPostData";
import { getAZGridData } from "../getData/getAZGridData";
import { getCTAData } from "../getData/getCTAData";
import { getAuthorsModuleData } from "../getData/getAuthorsModuleData";
import { getJobsData } from "../getData/getJobsData";
import { getInsightModuleData } from "../getData/getInsightModuleData";
import { getEncyclopediaInsightData } from "../getData/getEncyclopediaInsightData";
import { getHeaderPortfolioData } from "../getData/getHeaderPortfolioData";
import { getPortfolioModuleData } from "../getData/getPortfolioModuleData";
import { getTeamsData } from "../getData/getTeamsData";
import { getSeoListData } from "../getData/getSeoListData";
import Modules from "../utils/module-list";
import { InsightsModuleType } from "../components/Insights/Insights";
import { getRelativePathFromLink } from "../utils/url";
import Tooltip from "../components/Tooltip/Tooltip";
import { getLogosModuleData } from "../getData/getLogosModuleData";
import { LogoType } from "../components/Logos/Logos";
import { getAllPostsOrCustomPostTypes } from "../hooks/getAllPosts";
import { deepReplace } from "../utils/deepReplace";
import { createGlossaryLinks } from "../utils/createGlossaryLInks";
import {
  EphemeralStore,
  type EphemeralStoreKey,
} from "../utils/ephemeralStore";
const Insights = dynamic(() => import("../components/Insights/Insights"));
import ReactLenis from "lenis/dist/lenis-react";
import fixStucturedPageDataYoast from "../utils/fixStucturedPageDataYoast";
import getCachedGlossaryItems from "../utils/getCachedGlossaryItems";
interface DynamicPageProps {
  pageData: PageType | WikiType;
  legalMenu: MenuType;
  mainMenu: MenuType;
  options: OptionsType;
  categories: CategoryType[];
  footerMenu: MenuType;
  wikiCategories: EncyclopediaCategoryType[];
  wikiInfo: WikiType[];
  wikiFilterLetters: string[] | null;
  ctaPersons: PageType[];
  AuthorsGridSlider: PagenationDataType;
  JobsData: PageType[];
  context: GetStaticPropsContext;
  wikiPosts: AzFilterPostType;
  relatedPosts: WikiType[];
  filteredWikiPosts: WikiType[];
  autorInsights: AuthorInsight;
  InsightData: PagenationDataType;
  InsightCategoryData: CategoryDataType[];
  InsightHighlightedPosts: PageType[];
  azCategories: EncyclopediaCategoryType[];
  TeamsData: PageType[];
  PortfolioData: PagenationDataType;
  PortfolioCategoryData: PageType[];
  SEOList: SEOListType[];
  WikiInsightData: PagenationDataType;
  portraitSliderPortfolio: PageType[];
  PortraitSliderModulePortfolio: PageType[];
  totalWikiPosts: number;
  LogosData: LogoType[];
}

export default function DynamicPage({
  pageData,
  categories,
  legalMenu,
  mainMenu,
  options,
  footerMenu,
  wikiCategories,
  wikiFilterLetters,
  ctaPersons,
  AuthorsGridSlider,
  JobsData,
  context,
  wikiPosts,
  relatedPosts,
  filteredWikiPosts,
  autorInsights,
  InsightData,
  InsightCategoryData,
  azCategories,
  TeamsData,
  PortfolioData,
  PortfolioCategoryData,
  SEOList,
  WikiInsightData,
  portraitSliderPortfolio,
  PortraitSliderModulePortfolio,
  InsightHighlightedPosts,
  totalWikiPosts,
  LogosData,
}: DynamicPageProps) {
  const { slug } = pageData;
  const isSinglePage = checkSinglePage({ page: pageData });

  const router = useRouter();

  const lastIsPromo = pageData.acf?.module
    ? pageData.acf?.module[pageData.acf?.module?.length - 1].acf_fc_layout ===
      "promo_banner"
    : false;

  const dataWikiInsights = {
    acf_fc_layout: "insights",
    insights: {
      categories_on: false,
      headline: "",
      insights_type: "grid",
      grid_type: "load-more",
      htype_tag: "h2",
      divider: {
        bg_text: "insights",
        divider_on: true,
        headline_im_divider: false,
        subline: `${
          context.locale == "de"
            ? "Begierde <strong>Wissen</strong>"
            : "Desire <strong>Knowledge</strong>"
        }`,
      },
      posts_per_page: 3,
      posts_to_load: 3,
      categories: false,
    },
    layout: {
      id: "",
      background: "has-dark-grey-bg",
      abstand: "default",
      split: "one-third-screen",
      bild_typ: "normal",
    },
  } as unknown as InsightsModuleType;
  return (
    <>
      {pageData && (
        <>
          {(slug === "berlinfahrten" || isSinglePage) && <FullScreenLoader />}
          <HeadMeta post={pageData} options={options} />
          <MouseFollow />
          <InlineNav />
          <DividerBg />
          {/* //! NAVBAR COMP ALWAYS NEED TO BE ABOVE MAIN */}
          {slug === "berlinfahrten" || isSinglePage ? (
            <BNavbar page={pageData} options={options} />
          ) : (
            <Navbar options={options} menu={mainMenu} page={pageData} />
          )}

          {/* //! NAVBAR COMP ALWAYS NEED TO BE ABOVE MAIN */}
          {/*//! pelase dont remove the id "main" ☣️ */}
          <motion.main
            id="main"
            className={isSinglePage ? "is-single-page" : ""}
          >
            {!router.asPath.includes("joinus-success") && (
              <Header
                post={pageData}
                options={options}
                categories={categories}
                wikiCategories={wikiCategories}
                wikiInfo={wikiPosts}
                wikiFilterLetters={wikiFilterLetters}
                autorInsights={autorInsights}
                portraitSliderPortfolio={portraitSliderPortfolio}
              />
            )}

            {pageData.acf?.module &&
              Array.isArray(pageData.acf.module) &&
              pageData.acf.module.map((module, index) => {
                return (
                  <Modules
                    key={index}
                    module={module}
                    options={options}
                    ctaPersons={ctaPersons}
                    AuthorsGridSlider={AuthorsGridSlider}
                    JobsData={JobsData}
                    context={context}
                    filteredWikiPosts={filteredWikiPosts}
                    wikiPosts={wikiPosts}
                    wikiFilterLetters={wikiFilterLetters}
                    InsightData={InsightData}
                    InsightCategoryData={InsightCategoryData}
                    InsightHighlightedPosts={InsightHighlightedPosts}
                    azCategories={azCategories}
                    TeamsData={TeamsData}
                    PortfolioCategoryData={PortfolioCategoryData}
                    PortfolioData={PortfolioData}
                    SEOList={SEOList}
                    type={pageData.type}
                    pageId={pageData.id}
                    PortraitSliderModulePortfolio={
                      PortraitSliderModulePortfolio
                    }
                    totalWikiPosts={totalWikiPosts}
                    lastIsPromo={lastIsPromo}
                    LogosData={LogosData}
                  />
                );
              })}

            {pageData.type === "encyclopedia" && (
              <>
                <WikiRelatedPosts
                  relatedPosts={relatedPosts}
                  options={options}
                />
                <Search
                  options={options}
                  classes={"is-gapless-top has-grey-bg"}
                />
                <Insights
                  data={dataWikiInsights}
                  InsightCategoryData={[]}
                  InsightData={WikiInsightData}
                  context={context}
                  pageId={pageData.id}
                  pageType={pageData.type}
                  InsightHighlightedPosts={[]}
                />
              </>
            )}
            {slug === "berlinfahrten" || isSinglePage ? (
              <BFooter options={options} legalMenu={legalMenu} />
            ) : (
              <Footer
                options={options}
                menu={footerMenu}
                legalMenu={legalMenu}
              />
            )}
          </motion.main>
          <Tooltip key={pageData.slug} />
        </>
      )}
    </>
  );
}

export async function getStaticPaths(context: GetStaticPropsContext) {
  const allPaths = await globalGetStaticPaths();

  const categorySlugs = ["wiki", "insights", "portfolio"];
  const newPaths: any[] = [];

  allPaths.map((item) => {
    if (item.params) {
      const slug = item.params.dynamicRoute;
      if (categorySlugs.includes(slug[0]) && slug.length >= 2) {
        // you can put additional logic here for the language specific categorie names
        const slugWithCategory = [slug[0], "kategorie", slug[1]];
        newPaths.push({ params: { dynamicRoute: [...slugWithCategory] } });
      } else {
        newPaths.push(item);
      }
    }
  });

  // console.log("newPaths", JSON.stringify(newPaths));
  return {
    paths: newPaths,
    fallback: "blocking",
  };
}

export async function getStaticProps(context: GetStaticPropsContext) {
  const { dynamicRoute }: any = context.params;
  const locale = context.locale || "de";
  const defaultLocale = context.defaultLocale || "de";

  if (context.preview && typeof context.previewData !== "string") {
    return {
      notFound: true,
    };
  }
  const privatePageData = EphemeralStore.getEntry(
    context.previewData as EphemeralStoreKey
  );
  let pageData =
    context.preview && !privatePageData.isNone
      ? privatePageData.value
      : await globalGetPageData(dynamicRoute, locale, defaultLocale);
  let constructedPagePath, pagePath;

  if (dynamicRoute) {
    pagePath = getRelativePathFromLink(pageData?.link || "");
    constructedPagePath = `/${
      locale === defaultLocale ? "" : locale + "/"
    }${dynamicRoute.join("/")}/`;
  }
  if (!pageData || pagePath !== constructedPagePath) {
    return {
      notFound: true,
      revalidate: parseInt(process.env.REVALIDATE_AFTER || "120"),
    };
  }

  const fetchOptions = getOptions();
  const fetchCategories = getCategories(
    "categories",
    context.locale,
    context.defaultLocale
  );

  const legalMenuSlug = checkSinglePage({ page: pageData })
    ? "landing"
    : "legal";

  const fetchlegalMenu = getMenu(
    context.locale ? `${legalMenuSlug}-` + context.locale : legalMenuSlug
  );

  const fetchfooterMenu = getMenu(
    context.locale ? "footer-" + context.locale : "footer"
  );

  const fetchmainMenu = getMenu(
    context.locale ? "main-" + context.locale : "main"
  );

  const fetchauthorInfo = getAuthorHeaderPageData({ pageData, context });
  // if (authorInfo) pageData.author_info = authorInfo;

  const fetchProjectCategories = getProjectHeaderData({
    pageData,
    context,
  });

  // pageData.project_categories

  const fetchautorInsights = getAuthorInsightsData({ pageData, context });

  //! common wiki data
  const fetchWikiCommons = getWikiCommonData({ pageData, context });

  //! wiki module
  const fetchwikiPosts = getWikiPostData({ pageData, context });

  //! az_grid_module module
  const fetchAZGridData = getAZGridData({
    pageData,
    context,
  });

  //! CTA module
  const fetchctaPersons = getCTAData({ pageData, context });

  //! AuthorsGridSlider module
  const fetchAuthorsGridSlider = getAuthorsModuleData({ pageData, context });

  //! Jobs module and form type recruitment
  const fetchJobsData = getJobsData({ pageData, context });

  //! Insights module data
  const fetchInsightData = getInsightModuleData({ pageData, context });

  //! Insights data for encyclopedia page
  const fetchWikiInsightData = getEncyclopediaInsightData({
    pageData,
    context,
  });

  //! fetching data for portrait slider header portfolio
  const fetchPortraitSliderPortfolio = getHeaderPortfolioData({
    pageData,
    context,
  });

  //! portfolio data for portrait slider module
  const fetchPortraitSliderModulePortfolio = getPortraitSliderData({
    pageData,
    context,
  });

  //! portfolio data for portfolio module
  const fetchPortfolioData = getPortfolioModuleData({
    pageData,
    context,
  });

  //! data for team module
  const fetchTeamsData = getTeamsData({ pageData, context });

  //! data for SEO module
  const fetchSEOList = getSeoListData({ pageData, context });

  //! data for logos module
  const fetchLogosData = getLogosModuleData({ pageData, context });

  const [
    options,
    categories,
    legalMenu,
    footerMenu,
    mainMenu,
    authorInfo,
    projectCategories,
    autorInsights,
    wikiCommonData,
    wikiPosts,
    aZData,
    ctaPersons,
    AuthorsGridSlider,
    JobsData,
    insightModuleData,
    WikiInsightData,
    portraitSliderPortfolio,
    PortraitSliderModulePortfolio,
    portfolioModuleData,
    TeamsData,
    SEOList,
    LogosData,
  ] = await Promise.all([
    fetchOptions,
    fetchCategories,
    fetchlegalMenu,
    fetchfooterMenu,
    fetchmainMenu,
    fetchauthorInfo,
    fetchProjectCategories,
    fetchautorInsights,
    fetchWikiCommons,
    fetchwikiPosts,
    fetchAZGridData,
    fetchctaPersons,
    fetchAuthorsGridSlider,
    fetchJobsData,
    fetchInsightData,
    fetchWikiInsightData,
    fetchPortraitSliderPortfolio,
    fetchPortraitSliderModulePortfolio,
    fetchPortfolioData,
    fetchTeamsData,
    fetchSEOList,
    fetchLogosData,
  ]);

  pageData.project_categories = projectCategories;
  if (authorInfo) pageData.author_info = authorInfo;
  if (authorInfo && !authorInfo.link) pageData!.author_info!.link = null;

  const { relatedPosts, wikiCategories, wikiFilterLetters } = wikiCommonData;
  const { filteredWikiPosts, azCategories, totalWikiPosts } = aZData;

  const { InsightData, InsightCategoryData, InsightHighlightedPosts } =
    insightModuleData;
  const { PortfolioCategoryData, PortfolioData } = portfolioModuleData;

  //! setting id for modules if not exist
  if (pageData.acf) {
    if (Array.isArray(pageData.acf.module)) {
      const updatedModule = [];
      const modules = pageData.acf.module as any[];
      for (let index = 0; index < modules.length; index++) {
        const Module = modules[index];
        if (Module.layout) {
          if (!Module.layout.id) {
            Module.layout.id = uuidv4();
          }
        }
        updatedModule.push(Module);
      }
      pageData.acf.module = updatedModule;
    }
  }

  let allGlossaryEntries = await getCachedGlossaryItems();

  if (pageData?.type === "encyclopedia") {
    allGlossaryEntries = allGlossaryEntries.filter((entry) => {
      return (
        entry.slug !== pageData?.slug &&
        entry.acf?.wiki_header?.link_automatically
      );
    });
  } else
    allGlossaryEntries = allGlossaryEntries.filter((entry) => {
      return entry.acf?.wiki_header?.link_automatically;
    });

  const replacedWords = new Set<string>(); // Track replaced words
  const replacer = (value: any) => {
    if (typeof value === "string") {
      return createGlossaryLinks(
        value,
        (allGlossaryEntries as any) || [],
        replacedWords
      );
    } else return value;
  };

  deepReplace(pageData?.acf?.module, "content", replacer);

  const allHeaderTypes = [
    "header_seite",
    "header_portfolio",
    "header_insights",
    "header_project",
    "header_startseite",
    "wiki_header",
  ];

  pageData = fixStucturedPageDataYoast(pageData);

  // Glossar Entries in Header - Text
  allHeaderTypes.forEach((headerType) => {
    if (headerType === "header_startseite") {
      deepReplace(pageData?.acf?.[headerType], "content", replacer);
    } else {
      deepReplace((pageData as any)?.acf?.[headerType], "content", replacer);
      deepReplace((pageData as any)?.acf?.[headerType], "text", replacer);
    }
  });

  return {
    props: {
      pageData,
      options,
      categories,
      legalMenu,
      mainMenu,
      footerMenu,
      wikiCategories,
      wikiFilterLetters,
      ctaPersons,
      AuthorsGridSlider,
      context,
      wikiPosts,
      relatedPosts,
      filteredWikiPosts,
      JobsData,
      autorInsights,
      InsightData,
      InsightCategoryData,
      azCategories,
      TeamsData,
      PortfolioData,
      PortfolioCategoryData,
      SEOList,
      WikiInsightData,
      portraitSliderPortfolio,
      PortraitSliderModulePortfolio,
      InsightHighlightedPosts,
      totalWikiPosts,
      LogosData,
    },
    revalidate: parseInt(process.env.REVALIDATE_AFTER || "120"),
  };
}
